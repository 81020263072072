.btn:focus {
  box-shadow: none;
}

.tips-button {
  background-color: rgb(54, 182, 255);
  border-color: rgb(54, 182, 255);
  border-radius: 2rem;
  color: #000000;
  width: 150px;
}

.tips-button.selected {
  background-color: #0d6efd;
  color: #ffffff;
}

.time-button {
  background-color: #41c3c2;
  border-color: #41c3c2;
  border-radius: 2rem;
  color: #000000;
  width: 150px;
}

.time-button:hover {
  background-color: #1e7e7d;
  border-color: #1e7e7d;
}

.time-button:active {
  background-color: #1e7e7d;
  border-color: #1e7e7d;
}

.time-button-selected {
  background-color: #1e7e7d;
  color: #ffffff;
}
